import { Injectable } from '@angular/core';
import { StatusBar } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class StatusbarService {

  constructor(
    private platform: Platform
  ) {
  }

  async setStatusBarStyle(style): Promise<void> {
    if (this.platform.is('ios') && this.platform.platforms().includes('capacitor')) {
      await StatusBar.setStyle({ style });
    }
  }

}
