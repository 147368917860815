import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/auth/sign-in/sign-in.module').then(m => m.SignInPageModule)
  },
  {
    path: 'auth/sign-up',
    loadChildren: () => import('./pages/auth/sign-up/sign-up.module').then(m => m.SignUpPageModule)
  },
  {
    path: 'auth/lost-pin',
    loadChildren: () => import('./pages/auth/pin/lost/lost-pin.module').then(m => m.LostPinPageModule)
  },
  {
    path: 'auth/new-pin',
    loadChildren: () => import('./pages/auth/pin/new/new-pin.module').then(m => m.NewPinPageModule)
  },
  {
    path: 'auth/activation',
    loadChildren: () => import('./pages/auth/activation/activation.module').then(m => m.ActivationPageModule)
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'app/profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/profile/competitions',
    loadChildren: () => import('./pages/profile/components/competitions/competitions.module').then(m => m.CompetitionsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/profile/interested',
    loadChildren: () => import('./pages/profile/components/interested/interested.module').then(m => m.InterestedPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/profile/favourites',
    loadChildren: () => import('./pages/profile/components/favourites/favourites.module').then(m => m.FavouritesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/profile/base-settings',
    loadChildren: () => import('./pages/profile/components/base/base.module').then(m => m.BasePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/profile/personal-settings',
    loadChildren: () => import('./pages/profile/components/personal/personal.module').then(m => m.PersonalPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/profile/shop-preferences',
    loadChildren: () => import('./pages/profile/components/preferences/preferences.module').then(m => m.PreferencesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/about',
    loadChildren: () => import('./pages/contentPages/about/about.module').then(m => m.AboutPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/rating',
    loadChildren: () => import('./pages/contentPages/rating/rating.module').then(m => m.RatingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/recommendation',
    loadChildren: () => import('./pages/contentPages/recommendation/recommendation.module').then(m => m.RecommendationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/request',
    loadChildren: () => import('./pages/contentPages/request/request.module').then(m => m.RequestPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/app-settings',
    loadChildren: () => import('./pages/contentPages/appSettings/appSettings.module').then(m => m.AppSettingsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/faq',
    loadChildren: () => import('./pages/contentPages/faq/faq.module').then(m => m.FaqPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/partners',
    loadChildren: () => import('./pages/contentPages/partners/partners.module').then(m => m.PartnersPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/partners/:id/detail',
    loadChildren: () => import('./pages/contentPages/partners/components/partner-detail/partner-detail.module')
      .then(m => m.PartnerDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/terms',
    loadChildren: () => import('./pages/contentPages/terms/terms.module').then(m => m.TermsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/terms/:id/detail',
    loadChildren: () => import('./pages/contentPages/terms/components/terms-detail/terms-detail.module').then(m => m.TermsDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/blog',
    loadChildren: () => import('./pages/contentPages/blog/blog.module').then(m => m.BlogPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/blog/detail/:id',
    loadChildren: () => import('./pages/contentPages/blog/components/blog-detail/blog-detail.module').then(m => m.BlogDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/contacts',
    loadChildren: () => import('./pages/contentPages/contacts/contacts.module').then(m => m.ContactsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/benefits',
    loadChildren: () => import('./pages/benefits/benefits.module').then(m => m.BenefitsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/map',
    loadChildren: () => import('./pages/map/map.module').then(m => m.MapPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/actions',
    loadChildren: () => import('./pages/actions/actions.module').then(m => m.ActionsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/loyalty',
    loadChildren: () => import('./pages/loyalty/loyalty.module').then(m => m.LoyaltyPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/competition',
    loadChildren: () => import('./pages/competition/competition.module').then(m => m.CompetitionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/vouchers',
    loadChildren: () => import('./pages/vouchers/vouchers.module').then(m => m.VouchersPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/error',
    loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/item-detail/:id',
    loadChildren: () => import('./pages/item-detail/item-detail.module').then(m => m.ItemDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/sellers',
    loadChildren: () => import('./pages/sellers/sellers.module').then(m => m.SellersPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/sellers/:companyId/detail',
    loadChildren: () => import('./pages/sellers/components/seller-detail/seller-detail.module').then(m => m.SellerDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/sellers/:companyId/stores',
    loadChildren: () => import('./pages/sellers/components/stores/stores.module').then(m => m.SellerStoresPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/categories',
    loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/categories/:categoryId/detail',
    loadChildren: () => import('./pages/categories/components/category-detail/category-detail.module')
      .then(m => m.CategoryDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/search',
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
