import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {IonContent, ModalController} from '@ionic/angular';
import {register} from 'swiper/element/bundle';
import {PushService} from '../../services/push.service';
import {PushNotificationModel} from '../../models/pushNotification.model';
import {Subscription} from 'rxjs';

register();

@Component({
  selector: 'app-notifications',
  templateUrl: 'notifications.page.html',
  styleUrls: ['notifications.page.scss']
})
export class NotificationsPage {
  @ViewChild('swiper', {static: false}) swiperRef: ElementRef;
  @ViewChild(IonContent, {static: false}) content: IonContent;

  @Input()
  clickedNotification: PushNotificationModel;


  public activeSegment = 'all';
  public showSegments = true;
  public loaded = false;
  public slideOpts = {
    allowTouchMove: false
  };
  public notifications: Array<PushNotificationModel> = [];
  public filteredNotifications: Array<PushNotificationModel> = [];
  public notificationsSubscription: Subscription;
  public activeNotification: PushNotificationModel;

  constructor(
    private modalCtrl: ModalController,
    public pushSvc: PushService
  ) {
    this.notificationsSubscription = this.pushSvc.sharedNotifications.subscribe(async (resp) => {
      this.notifications = resp;
      if (this.notifications) {
        this.filteredNotifications = this.notifications.filter(n => !n.readAt);
      }
    });
  }

  async ionViewDidEnter(): Promise<void> {
    await this.swiperRef?.nativeElement.swiper.update();
    console.warn("CLICKED PUSH DATA: ", this.clickedNotification);
    try {
      await this.pushSvc.getAll();
      this.loaded = true;
    } catch (e) {
      this.loaded = true;
    }
  }

  async close(): Promise<void> {
    await this.modalCtrl.dismiss();
  }

  async navToDetail(notif: PushNotificationModel): Promise<void> {
    this.activeNotification = notif;
    this.showSegments = false;
    await this.swiperRef?.nativeElement.swiper.slideTo(1);
    await this.content.scrollToTop();
    await this.pushSvc.markAsRead(notif);
  }

  async navToList(): Promise<void> {
    this.activeNotification = null;
    this.showSegments = true;
    await this.swiperRef?.nativeElement.swiper.slideTo(0);
  }


}
