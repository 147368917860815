import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authSvc: AuthService,
    private router: Router,
    private apiSvc: ApiService,
    private toastCtrl: ToastController
  ) {
  }

  async canActivate(): Promise<boolean> {
    if (this.apiSvc.isLogged && this.apiSvc.isValid && this.authSvc.profile && !this.authSvc.profile.verified) {
      const toast = await this.toastCtrl.create({
        message: 'Pro přístup do aplikace musíte mít ověřený účet.',
        duration: 3000,
        position: 'bottom',
        cssClass: 'toast--danger'
      });
      await toast.present();
      await this.router.navigateByUrl('/auth/verification');
      return false;
    }
    if (!this.apiSvc.isLogged) {
      const toast = await this.toastCtrl.create({
        message: 'Pro přístup do aplikace se musíte přihlásit.',
        duration: 3000,
        position: 'bottom',
        cssClass: 'toast--warning'
      });
      await toast.present();
      await this.router.navigateByUrl('');
      return false;
    }
    return this.apiSvc.isLogged && this.apiSvc.isValid && (this.authSvc.profile && this.authSvc.profile.verified);
  }
}
