import { Injectable } from '@angular/core';


@Injectable()
export class ShadowRootHelper {
    datetimeStyles = '*{font-family: var(--ion-font-family-regular) !important;}' +
      '.calendar-days-of-week, .calendar-month-grid{height: auto !important; padding-inline: 0 !important}' +
      '.calendar-month, .calendar-body{height: 200px !important;' +
      '.day-of-week{font-family: var(--ion-font-family-regular) !important;font-size: 13px !important;}' +
      '.calendar-day{height: 35px !important; padding: 0 !important; font-family: var(--ion-font-family-regular) !important;' +
      'font-size: 14px !important;}' +
      '.calendar-day-active::after{border-radius: 4px !important; width: 20px !important; height: 20px !important}' +
      '.calendar-day-today::after{border-radius: 4px !important; width: 20px !important; height: 20px !important}'
    ;


    public constructor() {
    }

    public injectStyles(
        shadowRootElement: HTMLElement,
        insertBeforeSelector: string,
        styles: string
    ) {
        const root = shadowRootElement.shadowRoot;
        let styleAlreadyAdded = false;
        const currentStyleTags = Array.from(root.querySelectorAll('style'));
        currentStyleTags.forEach((element: HTMLStyleElement, index) => {
            if (element.innerHTML === styles) {
                styleAlreadyAdded = true;
            }
        });
        if (styleAlreadyAdded === false) {
            const newStyleTag = document.createElement('style');
            newStyleTag.innerHTML = styles;
            root.insertBefore(newStyleTag, root.querySelector(insertBeforeSelector));
        }
        return true;
    }
}
