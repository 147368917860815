import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  myCurrentPosition = { lat: 50.0870249, lng: 14.4185178 }; //Pražský orloj -výchozí pozice při zakázané geolokaci
  public alreadyLocated = false;


  constructor() {
  }

  public async geolocateMe(requestPath: string = ''): Promise<any> {
    try {
      this.alreadyLocated = true;
      console.warn('==== GEOLOCATION START ====', requestPath);
      const position = await Geolocation.getCurrentPosition({ enableHighAccuracy: true });
      console.warn('==== GEOLOCATION END ====', requestPath);
      this.myCurrentPosition = { lat: position.coords.latitude, lng: position.coords.longitude };
      return this.myCurrentPosition;

    } catch (e) {
      this.alreadyLocated = false;
      console.log('GEOLOCATION position ERR? ', e);
      return this.myCurrentPosition;

    }
  }

  public async isGeolocationAvailable(): Promise<boolean> {
    try {
      const permission = await Geolocation.checkPermissions();
      return permission.location === 'granted';
    } catch (e) {
      console.log('PERMISSION position ERR? ', e);
      return false;

    }
  }

  public async requestPermission(): Promise<boolean> {
    try {
      const permission = await Geolocation.requestPermissions({ permissions: ['location'] });
      console.log('PERMISSION: ', permission);
      return permission.location === 'granted';
    } catch (e) {
      console.log('PERMISSION position ERR? ', e);
      return false;

    }
  }

  public getLat() {
    if (this.myCurrentPosition) {
      return this.myCurrentPosition.lat;

    } else {
      this.geolocateMe();
    }
  }

  public getLng() {
    if (this.myCurrentPosition) {
      return this.myCurrentPosition.lng;

    } else {
      this.geolocateMe();
    }
  }
}
