import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable()
export class LoadingHelper {
  public loading: HTMLIonLoadingElement;
  private alertTitle = 'Prosím vydržte';
  private alertMessage = 'Právě načítáme obsah, připojení je příliš pomalé.';


  public constructor(
    private loadingCtrl: LoadingController
  ) {
  }

  public async presentLoader(title: string = this.alertTitle, message: string = this.alertMessage, place = null): Promise<any> {
    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;
    }
    this.loading = await this.loadingCtrl.create({
      cssClass: 'my-custom-class',
      spinner: 'circular',
      duration: 3000
    });
    console.log('====  LOADER PRESENTED ON: ' + place + '  ====');
    await this.loading.present();

  }

  public async dismissLoader(place = null): Promise<any> {
    console.log('====  LOADER DISMISSED ON: ' + place + '  ====');
    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;
    }
  }

}
