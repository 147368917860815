import { Injectable } from '@angular/core';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { SignInWithApple, SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';
import { Preferences } from '@capacitor/preferences';
import jwt_decode from 'jwt-decode';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { LoadingHelper } from '../helpers/loading.helper';
import { environment } from '../../environments/environment';
import { LinkedinService } from './linkedin.service';
import { LinkedIn } from 'ng2-cordova-oauth/core';
import { OauthCordova } from 'ng2-cordova-oauth/platform/cordova';
import * as Sentry from '@sentry/capacitor';

@Injectable({
  providedIn: 'root'
})
export class SocialsService {


  constructor(
    private loadingHelper: LoadingHelper,
    private linkedinSvc: LinkedinService
  ) {
  }

  async facebookLoginApi(): Promise<any> {
    try {
      const FACEBOOK_PERMISSIONS = [
        'email',
        'public_profile'
      ];
      const resultFbLogin = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
      await this.loadingHelper.presentLoader(null, null, 'facebookLoginApi()');
      if (resultFbLogin.accessToken) {
        const resultGetFbProfile = await FacebookLogin.getProfile<any>({ fields: ['id', 'email', 'first_name', 'last_name'] });
        return { profile: resultGetFbProfile, login: resultFbLogin };
      }
    } catch (e) {
      console.log('Error logging into FB', e);
      Sentry.captureException('Error logging into FB: ' + JSON.stringify(e));
      return e;
    }
  }

  async googleLoginApi(): Promise<any> {
    try {
      const resultGoogleLogin = await GoogleAuth.signIn();
      await this.loadingHelper.presentLoader(null, null, 'googleLoginApi()');

      if (resultGoogleLogin.authentication.accessToken) {
        const profile = {
          token: resultGoogleLogin.authentication.accessToken,
          email: resultGoogleLogin.email,
          fullName: resultGoogleLogin.givenName + ' ' + resultGoogleLogin.familyName
        };
        return profile;
      }
    } catch (e) {
      console.log('Error logging into GOOGLE', e);
      Sentry.captureException('Error logging into GOOGLE: ' + JSON.stringify(e));
      return e;
    }
  }

  async linkedInLoginApi(): Promise<any> {
    try {
      const provider = new LinkedIn({
        clientId: environment.linkedinClientId,
        appScope: ['r_emailaddress', 'r_liteprofile'],
        redirectUri: 'http://localhost/callback',
        responseType: 'code',
        state: this.linkedinSvc.getRandomState()
      });
      const oauth = new OauthCordova();
      const resp = await oauth.logInVia(provider);
      // @ts-ignore
      const respToken = await this.linkedinSvc.getAccessToken(resp.code);
      await this.loadingHelper.presentLoader(null, null, 'linkedInLoginApi()');
      if (respToken) {
        const accessToken = respToken.data.access_token;
        const namePromise = await this.linkedinSvc.getName(accessToken);
        //const picPromise = await this.linkedinSvc.getProfilePic(accessToken);
        const emailPromise = await this.linkedinSvc.getEmail(accessToken);

        const profile = {
          token: accessToken,
          email: emailPromise,
          fullName: namePromise
        };
        return profile;
      }
    } catch (e) {
      console.log('Error logging into LinkedIn', e);
      Sentry.captureException('Error logging into LinkedIn: ' + JSON.stringify(e));
      return e;
    }
  }

  async appleLoginApi(): Promise<any> {
    try {
      const options: SignInWithAppleOptions = {
        clientId: 'cz.vipbenefitclub.app',
        redirectURI: 'https://app.vipbenefitclub.cz',
        scopes: 'email name'
      };
      const resp = await SignInWithApple.authorize(options);
      await this.loadingHelper.presentLoader(null, null, 'appleLoginApi()');
      if (resp.response && resp.response.identityToken) {
        if (resp.response.email) {
          await Preferences.set({ key: 'apple-credentials', value: JSON.stringify(resp.response) });
          const profile = {
            token: resp.response.identityToken,
            email: resp.response.email,
            fullName: resp.response.givenName + ' ' + resp.response.familyName
          };
          return profile;
        } else {
          const { value } = await Preferences.get({ key: 'apple-credentials' });
          if (value) {
            const profile = {
              token: JSON.parse(value).identityToken,
              email: JSON.parse(value).email,
              fullName: JSON.parse(value).givenName + ' ' + JSON.parse(value).familyName
            };
            return profile;
          } else {
            const profile = {
              token: resp.response.identityToken,
              email: this.getDecodedAccessToken(resp.response.identityToken).email,
              fullName: resp.response.givenName + ' ' + resp.response.familyName
            };
            return profile;
          }
        }
      }
    } catch (e) {
      console.log('Error logging into Apple', e);
      Sentry.captureException('Error logging into Apple: ' + JSON.stringify(e));
      return e;
    }
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }


}
