import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import packageInfo from '../package.json';
import { Integrations } from '@sentry/tracing';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.sentryUrl,
  release: `vip-bc@${packageInfo.version}`,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', 'https://dev-api-vipbc.techcrowd.space/api/', 'https://api-vipbc.techcrowd.space/api/'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 1.0,
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));


// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
