import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { SignUpResponseModel } from '../Models/signUpResponse.model';
import { SignInResponseModel } from '../Models/signInResponse.model';
import { UserModel } from '../Models/user.model';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { Preferences } from '@capacitor/preferences';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LoadingHelper } from '../helpers/loading.helper';
import { Platform } from '@ionic/angular';
import { EventsService } from './events.service';
import { environment } from '../../environments/environment';
import { SocialsService } from './socials.service';
import * as Sentry from '@sentry/capacitor';
import { PreferencesService } from './preferences.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public profile: UserModel;
  public userSubject: any = new BehaviorSubject<UserModel[]>(null);
  public user: any = this.userSubject.asObservable();
  public profileProgress = 0;
  public personalProfileProgress = 0;
  public preferencesProgress = 0;
  public baseProfileProgress = 0;
  profileSubscription: Subscription;

  constructor(
    private apiSvc: ApiService,
    private loadingHelper: LoadingHelper,
    private platform: Platform,
    private eventsSvc: EventsService,
    private socialsSvc: SocialsService,
    private preferencesSvc: PreferencesService
  ) {
    this.profileSubscription = this.user.subscribe(async (user) => {
      this.profile = user;
      if (this.profile) {
        await this.setProfileProgress();
      }
    });
  }

  public async setProfileProgress() {
    this.baseProfileProgress = 0;
    this.profileProgress = 0;
    this.preferencesProgress = 0;
    this.personalProfileProgress = 0;
    if (this.profile.firstname) {
      this.baseProfileProgress += 25;
    }
    if (this.profile.lastname) {
      this.baseProfileProgress += 25;
    }
    if (this.profile.avatar) {
      this.baseProfileProgress += 25;
    }
    if (this.profile.addressFull) {
      this.personalProfileProgress += 100 / 3;
    }
    if (this.profile.gender) {
      this.personalProfileProgress += 100 / 3;
    }
    if (this.profile.birthDate) {
      this.personalProfileProgress += 100 / 3;
    }

    const preferencesTypes = await this.preferencesSvc.getPreferenceTypes();
    const userPreferences = [];
    for (const preferenceType of preferencesTypes) {
      let preferenceTypeAnswersLength = 0;
      for (const preference of preferenceType.preferences) {
        if (this.profile.preferences.filter(p => p.preference && p.preference.id === preference.id).length > 0) {
          preferenceTypeAnswersLength += 1;
        }
      }
      userPreferences.push((preferenceTypeAnswersLength / preferenceType.preferences.length) * 100);
    }
    for (const userPref of userPreferences) {
      const val = (userPref / 100) * (100 / preferencesTypes.length);
      this.preferencesProgress += val;
    }

    const { value } = await Preferences.get({ key: 'profilePinChanged' });
    if (value) {
      this.baseProfileProgress += 25;
    }
    this.profileProgress += (this.baseProfileProgress / 3) + (this.personalProfileProgress / 3) + (this.preferencesProgress / 3);
  }

  public getAvatarUrl(url): string {
    if (url.includes('apivipbc')) {
      url = environment.baseUrl + url;
    }
    return url;
  }

  public async updateUserInLocalstorage(data): Promise<void> {
    await Preferences.set({ key: 'user', value: JSON.stringify(data) });
  }

  async loginSocial(socialType: any): Promise<void> {
    await this.loadingHelper.presentLoader(null, null, 'socialLogin()');
    try {
      const socialLoginResp = await this.socialLogin(socialType);
      if (socialLoginResp) {
        let payload;
        switch (socialType) {
          case 'facebook':
            payload = {
              type: 'facebook',
              token: socialLoginResp.login.accessToken.token,
              email: socialLoginResp.profile.email,
              fullname: socialLoginResp.profile.first_name + ' ' + socialLoginResp.profile.last_name
            };
            break;
          case 'google':
            payload = {
              type: 'google',
              token: socialLoginResp.token,
              email: socialLoginResp.email,
              fullname: socialLoginResp.fullName
            };
            break;
          case 'linkedIn':
            payload = {
              type: 'linkedin',
              token: socialLoginResp.token,
              email: socialLoginResp.email,
              fullname: socialLoginResp.fullName
            };
            break;
          case 'apple':
            payload = {
              type: 'apple',
              token: socialLoginResp.token,
              email: socialLoginResp.email,
              fullname: socialLoginResp.fullName
            };
            break;
        }
        const signInResp = await this.socialSignIn(payload);
        if (!!signInResp.refreshToken && !!signInResp.accessToken) {
          await this.apiSvc.saveToken(signInResp.refreshToken, signInResp.accessToken, signInResp.expiresAt);
          await this.eventsSvc.publish('sign-in', signInResp);
          await Preferences.set({ key: 'login-type', value: socialType });
        }
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException('Social Login error: ' + JSON.stringify(e));
      await this.loadingHelper.dismissLoader('socialLogin() error');
    }
  }


  public async socialLogin(socialType: any): Promise<any> {
    switch (socialType) {
      case 'facebook':
        if (!this.platform.is('capacitor')) {
          await FacebookLogin.initialize({ appId: '586593796540241' });
        }
        return await this.socialsSvc.facebookLoginApi();
        break;
      case 'google':
        return await this.socialsSvc.googleLoginApi();
        break;
      case 'linkedIn':
        return await this.socialsSvc.linkedInLoginApi();
        break;
      case 'apple':
        return await this.socialsSvc.appleLoginApi();
    }
  }

  public async signUp(data: any): Promise<SignUpResponseModel> {
    return await this.apiSvc.send<SignUpResponseModel>('POST', 'auth/register', data);
  }

  public async signIn(data: any): Promise<SignInResponseModel> {
    return await this.apiSvc.send<SignInResponseModel>('POST', 'auth/login', data);
  }

  public async socialSignIn(data: any): Promise<any> {
    return await this.apiSvc.send<any>('POST', 'auth/social-login', data);
  }

  public async codeResend(data: any): Promise<any> {
    return await this.apiSvc.send<any>('POST', 'user/resend', data);
  }

  public async verify(data: any): Promise<any> {
    return await this.apiSvc.send<any>('POST', 'user/verification', data);
  }

  public async resetPassword(data: any): Promise<any> {
    return await this.apiSvc.send<any>('PUT', 'users/reset-pin', data);
  }

  public async getLoggedUser(): Promise<UserModel> {
    const resp = await this.apiSvc.send<UserModel>('GET', 'auth/logged-user', null, false);
    this.userSubject.next(resp);
    return resp;
  }

  public async logout(): Promise<void> {
    return await this.apiSvc.send('DELETE', 'auth/logout');
  }

  public async removeAvatar(userId): Promise<void> {
    return await this.apiSvc.send('DELETE', 'user/delete-avatar?id=' + userId);
  }

  public async updateUser(data, userId): Promise<void> {
    return await this.apiSvc.send('PUT', 'user/' + userId, data);
  }

  public async updateNotificationToken(data: any): Promise<any> {
    return this.apiSvc.send<any>('PUT', 'notification/token', data);
  }

}
