import { Injectable } from '@angular/core';

@Injectable()
export class AccordionHelper {

  accordionMinHeight = 50;
  accordionMaxHeight = 150;
  accordionFoldHeight = 50;

  accordionBenefitsHeight = 150;
  accordionBenefitsSwipeMoved = 0;
  accordionBenefitsSwipeStartY = 0;
  accordionBenefitsSwipeCurrentY = 0;
  accordionBenefitsSwipeDirection = 'down';

  accordionSellersHeight = 150;
  accordionSellersSwipeMoved = 0;
  accordionSellersSwipeStartY = 0;
  accordionSellersSwipeCurrentY = 0;
  accordionSellersSwipeDirection = 'down';


  public constructor() {
  }


  showAccordion(type) {
    switch (type) {
      case 'benefits':
        this.accordionBenefitsHeight = this.accordionMaxHeight;

        break;
      case 'sellers':
        this.accordionSellersHeight = this.accordionMaxHeight;

        break;
    }
  }

  onAccordionMoveEnd(detail, type) {
    switch (type) {
      case 'benefits':
        switch (this.accordionBenefitsSwipeDirection) {
          case 'up':
            if ((detail.deltaY * -1) <= this.accordionFoldHeight) {
              this.accordionBenefitsSwipeMoved = 0;
              this.accordionBenefitsHeight = this.accordionMinHeight;
            } else {
              this.accordionBenefitsSwipeMoved = 0;
              this.accordionBenefitsHeight = this.accordionMaxHeight;
            }
            break;
          case 'down':
            if (detail.deltaY <= this.accordionFoldHeight) {
              this.accordionBenefitsSwipeMoved = 0;
              this.accordionBenefitsHeight = this.accordionMaxHeight;
            } else {
              this.accordionBenefitsSwipeMoved = 0;
              this.accordionBenefitsHeight = this.accordionMinHeight;
            }
            break;
        }
        break;
      case 'sellers':
        switch (this.accordionSellersSwipeDirection) {
          case 'up':
            if ((detail.deltaY * -1) <= this.accordionFoldHeight) {
              this.accordionSellersSwipeMoved = 0;
              this.accordionSellersHeight = this.accordionMinHeight;
            } else {

              this.accordionSellersSwipeMoved = 0;
              this.accordionSellersHeight = this.accordionMaxHeight;
            }
            break;
          case 'down':
            if (detail.deltaY <= this.accordionFoldHeight) {
              this.accordionSellersSwipeMoved = 0;
              this.accordionSellersHeight = this.accordionMaxHeight;
            } else {
              this.accordionSellersSwipeMoved = 0;
              this.accordionSellersHeight = this.accordionMinHeight;
            }
            break;
        }
        break;
    }
  }

  onAccordionMove(detail, type) {
    switch (type) {
      case 'benefits':
        this.accordionBenefitsSwipeStartY = detail.startY;
        this.accordionBenefitsSwipeCurrentY = detail.currentY;
        if (this.accordionBenefitsSwipeStartY > this.accordionBenefitsSwipeCurrentY) {
          this.accordionBenefitsSwipeDirection = 'up';
        } else {
          this.accordionBenefitsSwipeDirection = 'down';
        }

        switch (this.accordionBenefitsSwipeDirection) {
          case 'up':
            if (this.accordionBenefitsHeight < this.accordionMaxHeight) {
              if ((detail.deltaY * -1) > this.accordionFoldHeight) {
                this.accordionBenefitsSwipeMoved = 0;
                this.accordionBenefitsHeight = this.accordionMaxHeight;
              } else {
                this.accordionBenefitsSwipeMoved = detail.deltaY * -1;
              }
            }
            break;
          case 'down':
            if (this.accordionBenefitsHeight > this.accordionMinHeight) {
              if (detail.deltaY > this.accordionFoldHeight) {
                this.accordionBenefitsSwipeMoved = 0;
                this.accordionBenefitsHeight = this.accordionMinHeight;
              } else {
                this.accordionBenefitsSwipeMoved = detail.deltaY * -1;

              }
            }
            break;
        }
        break;
      case 'Sellers':
        this.accordionSellersSwipeStartY = detail.startY;
        this.accordionSellersSwipeCurrentY = detail.currentY;
        if (this.accordionSellersSwipeStartY > this.accordionSellersSwipeCurrentY) {
          this.accordionSellersSwipeDirection = 'up';
        } else {
          this.accordionSellersSwipeDirection = 'down';
        }
        switch (this.accordionSellersSwipeDirection) {
          case 'up':
            if (this.accordionSellersHeight < this.accordionMaxHeight) {
              if ((detail.deltaY * -1) > this.accordionFoldHeight) {
                this.accordionSellersSwipeMoved = 0;
                this.accordionSellersHeight = this.accordionMaxHeight;
              } else {
                this.accordionSellersSwipeMoved = detail.deltaY * -1;
              }
            }
            break;
          case 'down':
            if (this.accordionSellersHeight > this.accordionMinHeight) {
              if (detail.deltaY > this.accordionFoldHeight) {
                this.accordionSellersSwipeMoved = 0;
                this.accordionSellersHeight = this.accordionMinHeight;
              } else {
                this.accordionSellersSwipeMoved = detail.deltaY * -1;

              }
            }
            break;
        }
        break;
    }

  }

}

