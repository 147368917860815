import { NgModule, LOCALE_ID, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgHeroiconsModule } from '@dimaslz/ng-heroicons';
import { CommonModule, DecimalPipe, registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LoadingHelper } from './helpers/loading.helper';
import { SimpleMaskModule } from 'ngx-ion-simple-mask';
import { ShadowRootHelper } from './helpers/shadowRoot.helper';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FunctionsHelper } from './helpers/functions.helper';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import * as Sentry from '@sentry/angular';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { AccordionHelper } from './helpers/accordion.helper';


registerLocaleData(localeCs, 'cs');

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    CommonModule,
    IonicModule.forRoot(),
    NgCircleProgressModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      defaultLanguage: 'cs'

    }),
    AppRoutingModule,
    NgHeroiconsModule,
    SimpleMaskModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => (): any => {
      },
      deps: [Sentry.TraceService],
      multi: true
    },
    LoadingHelper,
    ShadowRootHelper,
    FunctionsHelper,
    AccordionHelper,
    InAppBrowser,
    LaunchNavigator,
    DecimalPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
