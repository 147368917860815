import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { BenefitModel } from '../models/benefit.model';
import { PreferenceModel } from '../models/preference.model';
import { PreferenceTypeModel } from '../models/preferenceType.model';


@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  public preferences: any = new BehaviorSubject<Array<any>>(null);
  public sharedPreferences: any = this.preferences.asObservable();


  public preferenceTypes: any = new BehaviorSubject<Array<any>>(null);
  public sharedPreferenceTypes: any = this.preferenceTypes.asObservable();


  constructor(
    private apiSvc: ApiService
  ) {

  }

  public async getPreferences(): Promise<{
    preferences: Array<PreferenceModel>;
    preferencesTypes: Array<PreferenceTypeModel>;
  }> {
    const respPreferences = await this.apiSvc.send<Array<PreferenceModel>>('GET', 'preferences');
    const respPreferenceTypes = await this.getPreferenceTypes();
    return { preferences: respPreferences, preferencesTypes: respPreferenceTypes };
  }

  public async save(data): Promise<any> {
    return await this.apiSvc.send<any>('POST', 'preferences/user', data);
  }

  public async getPreferenceTypes(): Promise<Array<PreferenceTypeModel>> {
    return await this.apiSvc.send<Array<PreferenceTypeModel>>('GET', 'preference-types');
//    return this.preferenceTypes.next(resp);
  }


}
