import {Component, OnInit} from '@angular/core';
import {MenuController, Platform} from '@ionic/angular';
import {StatusBar, Style} from '@capacitor/status-bar';
import {Router} from '@angular/router';
import {register} from 'swiper/element/bundle';
import {SplashScreen} from '@aparajita/capacitor-splash-screen';
import {Subscription} from 'rxjs';
import {EventsService} from './services/events.service';
import {ApiService} from './services/api.service';
import {AuthService} from './services/auth.service';
import {LoadingHelper} from './helpers/loading.helper';
import {Preferences} from '@capacitor/preferences';
import {UserModel} from './models/user.model';
import {TranslateService} from '@ngx-translate/core';
import {AnalyticsService} from './services/analytics.service';
import {FirebaseAnalytics} from '@capacitor-community/firebase-analytics';
import {PushService} from './services/push.service';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public routerHidden = true;
  public showSplash = true;
  public currentPage: string;
  profileSubscription: Subscription;
  loginSubscription: Subscription;
  logoutSubscription: Subscription;
  splashSubscription: Subscription;
  public profile: UserModel;

  constructor(
    public platform: Platform,
    private menuCtrl: MenuController,
    private router: Router,
    private eventsSvc: EventsService,
    private apiSvc: ApiService,
    public authSvc: AuthService,
    private loadingHelper: LoadingHelper,
    private translateSvc: TranslateService,
    private analyticsSvc: AnalyticsService,
    private pushSvc: PushService
  ) {
    this.loginSubscription = this.eventsSvc.subscribe('sign-in', async (data) => {
      console.log('login event recieved', data);
      await this.onLogin();
    });

    this.logoutSubscription = this.eventsSvc.subscribe('logout', async (data) => {
      console.log('logout event recieved', data);
      await this.onLogout();
    });

    this.splashSubscription = this.eventsSvc.subscribe('splash', async (data) => {
      console.log('splash event recieved', data);
      await this.onSplashHide();
    });
  }

  public async setRoot(url: any, params: any = {}): Promise<boolean> {
    return await this.router.navigate([url], {replaceUrl: true});
  }

  async ngOnInit() {
    await this.apiSvc.init();
    await this.platform.ready();

    if (this.platform.is('ios') && this.platform.platforms().includes('capacitor')) {
      await StatusBar.setStyle({style: Style.Light});
    }
    await this.analyticsSvc.initFb();

    console.log('this.apiSvc.isLogged', this.apiSvc.isLogged);
    console.log('this.apiSvc.isValid', this.apiSvc.isValid);
    this.translateSvc.setDefaultLang('cs');
    //await this.geolocationSvc.geolocateMe();
    if (this.apiSvc.isLogged && this.apiSvc.isValid) {
      await this.onLogin();
    } else if (this.apiSvc.isLogged && !this.apiSvc.isValid) {
      await this.apiSvc.refresh();
      await this.onLogin();
      console.log('refresh');
    } else {
      console.log('logout');
      await this.apiSvc.logout();
    }

  }


  public async onSplashHide() {
    try {
      setTimeout(async () => {
        SplashScreen.hide({
          fadeOutDuration: 300
        }).catch((e) => {
          console.log(e);
        });
        console.log('==== AFTER SPLASH HIDE ====');

      }, 300);
      this.showSplash = false;
      this.routerHidden = false;
      console.log('==== AFTER SPLASH HIDE ====');
    } catch (e) {
      console.log(e);
    }
  }

  public async setRootPage(isLogged) {
    try {
      if (isLogged) {
        /*
        const { value } = await Preferences.get({ key: 'user' });
        if (value) {
          this.authSvc.userSubject.next(JSON.parse(value));
        } else {*/

        const resp = await this.authSvc.getLoggedUser();
        if (resp) {
          await Preferences.set({key: 'user', value: JSON.stringify(resp)});
          // }
          console.log(this.authSvc.profile);

          if (this.authSvc.profile.verified) {
            return this.setRoot('/app'); //user is verified
          } else {
            return this.setRoot('/auth/activation'); //user no verified
          }
        }
      } else {
        return this.setRoot('');
      }
    } catch (e) {
      console.log(e);
      return false;
    }

  }

  async ionViewWillLeave(): Promise<void> {
    //this.authSvc.user.unsubscribe();
  }

  async onMenuClose(): Promise<void> {
    this.profileSubscription.unsubscribe();
  }

  async onMenuOpen(): Promise<void> {
    this.currentPage = this.router.url;
    this.profileSubscription = this.authSvc.user.subscribe(async (user) => {
      this.profile = user;
      console.log('LOGGED USER: ', user);
      if (this.profile && !this.profile.verified) {
        return await this.setRoot('/auth/activation', {replaceUrl: true});
      }
    });
  }

  async closeMenu(): Promise<void> {
    await this.menuCtrl.close();
  }

  async logout(): Promise<void> {
    await this.loadingHelper.presentLoader(null, null, 'logout() app');
    try {
      await this.authSvc.logout();
      await this.apiSvc.logout();
      await this.menuCtrl.close();
      await this.loadingHelper.dismissLoader('logout() app');
    } catch (e) {
      console.log(e);
      await this.loadingHelper.dismissLoader('logout() app error');

    }
  }

  private async onLogin() {
    try {
      await this.setRootPage(true);
      if (this.platform.platforms().includes('capacitor')) {
        await this.pushSvc.init();
      }

    } catch (e) {
      console.log(e);
    }
  }

  private async onLogout(): Promise<void> {
    await this.setRootPage(false);
  }

}
