import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { finalize, timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Token } from '../Models/token';
import * as moment from 'moment';
import { Preferences } from '@capacitor/preferences';
import { RefreshTokenResponse } from '../Models/accessToken/refreshTokenResponse';
import { RefreshTokenRequest } from '../Models/accessToken/refreshTokenRequest';
import { EventsService } from './events.service';
import { ToastController } from '@ionic/angular';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { UserModel } from '../models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsHelper } from '../helpers/functions.helper';
import { Http, HttpOptions } from '@capacitor-community/http';
import { GeolocationService } from './geolocation.service';
import { Md5 } from 'ts-md5';

declare const google;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public token: Token = {
    refreshToken: null,
    accessToken: null,
    expiresAt: null
  };
  public refreshPromise: Promise<string>;
  private timeOut = 20000;

  constructor(
    private http: HttpClient,
    private eventsSrv: EventsService,
    private toastCtrl: ToastController,
    private translateSvc: TranslateService,
    private functionsHelper: FunctionsHelper,
    private geoSrv: GeolocationService
  ) {
  }

  public get isLogged(): boolean {
    return !!this.token.refreshToken;
  }

  public get isValid(): boolean {
    return !!this.token.expiresAt && moment(moment(this.token.expiresAt).format()).isAfter(moment().format());
  }

  public async init() {
    await this.loadToken();
  }


  public async uploadImage(userId, blobData, name, ext): Promise<UserModel> {
    const formData = new FormData();
    formData.append('file', blobData, `${name}.${ext}`);
    return this.send<UserModel>('POST', 'user/upload-avatar?id=' + userId, formData);
  }

  public async send<T>(
    method: string,
    path: string,
    body: any = null,
    sendCoords: boolean = true
  ): Promise<T> {
    const options: any = {
      body,
      headers: new HttpHeaders()
    };
    return await this.request(method, path, options, sendCoords);
  }

  public getFormData(params: object): FormData {
    const fd: FormData = new FormData();
    for (const i in params) {
      if (Object.prototype.hasOwnProperty.call(params, params[i])) {
        fd.append(i, params[i]);
      }
    }
    return fd;
  }

  public async logout(sendEvent: boolean = true): Promise<void> {
    try {
      await this.saveToken(null, null, null);
      await Preferences.remove({ key: 'user' });
      const { value } = await Preferences.get({ key: 'login-type' });
      if (value) {
        switch (value) {
          case 'google':
            await GoogleAuth.signOut();
            break;
          case 'facebook':
            await FacebookLogin.logout();
            break;
        }
        await Preferences.remove({ key: 'login-type' });
      }
    } catch (e) {
      console.log('LOGOUT SAVE TOKEN ERR', e);
    }
    if (sendEvent) {
      this.eventsSrv.publish('logout');
    }
  }

  public refresh(): Promise<string> {
    if (!!this.refreshPromise) {
      return this.refreshPromise;
    }

    this.refreshPromise = new Promise<string>(async (resolve, reject) => {
      try {
        if (!this.token.refreshToken) {
          throw new Error('No refresh token');
        }

        const fullPath = `${environment.apiUrl}auth/refresh-token`;
        const options = {
          body: {
            refreshToken: this.token.refreshToken
          } as RefreshTokenRequest
        };
        const response: RefreshTokenResponse = await this.http
          .request<RefreshTokenResponse>(
            'POST',
            fullPath,
            options
          ).pipe(timeout(this.timeOut))
          .toPromise();
        await this.saveToken(response.refreshToken, response.accessToken, response.expiresAt);
        this.refreshPromise = null;
        resolve(response.accessToken);
      } catch (e) {
        console.log('error', 'api.ts, refresh', e);
        await this.logout(true);
        this.refreshPromise = null;
        reject();
      }
    });
    return this.refreshPromise;
  }

  public async saveToken(refresh: string, access: string, expiresAt: string) {
    try {
      this.token = new Token(refresh, access, expiresAt);
      await Preferences.set({ key: 'token', value: JSON.stringify(this.token) });
    } catch (e) {
      console.log('error', 'api.ts, saveToken', e);
    }
  }

  public async handleRequestError(httpErrorResponse: HttpErrorResponse): Promise<void> {
    let message = 'Něco se pokazilo, zkuste to prosím za chvilku.';
    console.log('handleRequestError', httpErrorResponse);
    if (httpErrorResponse.error && httpErrorResponse.error.message) {
      message = this.translateSvc.instant(this.functionsHelper.slugify(httpErrorResponse.error.message));
      if (httpErrorResponse.error.message === 'User does not exists!') {
        message = this.translateSvc.instant('user-does-not-exists');
      }
    }
    if (httpErrorResponse.error && httpErrorResponse.error.error) {
      message = this.translateSvc.instant(this.functionsHelper.slugify(httpErrorResponse.error.error));
    }
    console.log(message);
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      position: 'top',
      cssClass: 'toast toast--danger'
    });
    await toast.present();
  }

  public async getGooglePlaceInfo(placeId): Promise<any> {
    try {
      const options: HttpOptions = {
        url: 'https://maps.googleapis.com/maps/api/place/details/json?place_id=' + placeId + '&key=AIzaSyCnrwLX7Dxr0UmkF7wpT99i9Sj5I12s5tw'
      };
      return await Http.get(options);
      /*
      const url =;
      const response = await this.http.request('GET', url).pipe(timeout(this.timeOut)).toPromise();
      console.log('GOOGLE PLACE INFO: ', response);
*/
    } catch (e) {
      console.error('error', 'api.ts, getGooglePlaceInfo', e);
    }
  }


  public async saveRating(data: any): Promise<any> {
    return await this.send<any>('POST', 'ratings', data);
  }


  private async request(method: string, path: string, options, sendCoords: boolean = true): Promise<any> {
    let tries = 3;
    while (tries-- >= 0) {
      try {
        if (this.isLogged && !this.isValid) {
          const accessToken = await this.refresh();
          this.token.accessToken = accessToken;
        }

        if (this.token && !!this.token.accessToken) {
          options.headers = options.headers.set('Authorization', 'Bearer ' + this.token.accessToken);
        }
        if (sendCoords && await this.geoSrv.isGeolocationAvailable()) {
          if (!this.geoSrv.alreadyLocated) {
            await this.geoSrv.geolocateMe(path);
          }
          options.headers = options.headers.set('X-lat', this.geoSrv.myCurrentPosition.lat.toString());
          options.headers = options.headers.set('X-lng', this.geoSrv.myCurrentPosition.lng.toString());
        }
        options.headers = options.headers.set('X-app-id', this.returnHashedID(environment.appId));
        options.headers = options.headers.set('Accept-Language', 'cs');
        const fullPath = environment.apiUrl + path;
        const response = await this.http.request(method, fullPath, options).pipe(timeout(this.timeOut)).toPromise();
        return response;

      } catch (e) {
        console.log(tries);
        if (tries === 0) {
          if (e && e.status !== 401 && e.status !== 403) {
            throw e;
          } else {
            this.token.accessToken = null;
          }
        }
      }
    }
  }


  private returnHashedID(appId): string {
    return Md5.hashStr(appId + environment.appIdSalt);

  }

  private async loadToken() {
    try {
      this.token = new Token(null, null, null);
      const { value } = await Preferences.get({ key: 'token' });
      if (value) {
        this.token.refreshToken = JSON.parse(value).refreshToken;
        this.token.accessToken = JSON.parse(value).accessToken;
        this.token.expiresAt = JSON.parse(value).expiresAt;
      }
    } catch (e) {
      console.error('error', 'api.ts, loadToken', e);
    }
  }

}
