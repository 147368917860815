import { Injectable } from '@angular/core';
import { ActionSheetController, Platform } from '@ionic/angular';
import { LaunchNavigator, LaunchNavigatorOptions } from '@awesome-cordova-plugins/launch-navigator/ngx';

@Injectable()
export class FunctionsHelper {

  public constructor(
    private actionSheetCtrl: ActionSheetController,
    private launchNavigator: LaunchNavigator,
    private platform: Platform
  ) {
  }

  public slugify(text: string): string {
    return text
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');
  }

  decodeBase64(base64: string): any {
    return atob(base64);
  }

  async presentNavigateSheet(lat: string, lng: string) {
    console.log(lat, lng);
    const buttons: any = [{
      text: '',
      icon: 'close',
      cssClass: 'close',
      handler: () => {
        console.log('Cancel clicked');
      }
    }];

    const options = {
      app: ''
    };

    if (await this.launchNavigator.isAppAvailable(this.launchNavigator.APP.HERE_MAPS)) {
      buttons.unshift({
        text: this.launchNavigator.getAppDisplayName(this.launchNavigator.APP.HERE_MAPS),
        handler: async () => {
          options.app = this.launchNavigator.APP.HERE_MAPS;
          await this.launchNavigator.navigate(lat + ', ' + lng, options);
        }
      });
    }
    if (await this.launchNavigator.isAppAvailable(this.launchNavigator.APP.SYGIC)) {
      buttons.unshift({
        text: this.launchNavigator.getAppDisplayName(this.launchNavigator.APP.SYGIC),
        handler: async () => {
          options.app = this.launchNavigator.APP.SYGIC;
          await this.launchNavigator.navigate(lat + ', ' + lng, options);
        }
      });
    }
    if (await this.launchNavigator.isAppAvailable(this.launchNavigator.APP.WAZE)) {
      buttons.unshift({
        text: this.launchNavigator.getAppDisplayName(this.launchNavigator.APP.WAZE),
        handler: async () => {
          options.app = this.launchNavigator.APP.WAZE;
          await this.launchNavigator.navigate(lat + ', ' + lng, options);
        }
      });
    }
    if (await this.launchNavigator.isAppAvailable(this.launchNavigator.APP.GOOGLE_MAPS)) {
      buttons.unshift({
        text: this.launchNavigator.getAppDisplayName(this.launchNavigator.APP.GOOGLE_MAPS),
        handler: async () => {
          options.app = this.launchNavigator.APP.GOOGLE_MAPS;
          await this.launchNavigator.navigate(lat + ', ' + lng, options);

        }
      });
    }
    if (await this.launchNavigator.isAppAvailable(this.launchNavigator.APP.APPLE_MAPS) && this.platform.is('ios')) {
      buttons.unshift({
        text: this.launchNavigator.getAppDisplayName(this.launchNavigator.APP.APPLE_MAPS),
        handler: async () => {
          options.app = this.launchNavigator.APP.APPLE_MAPS;
          await this.launchNavigator.navigate(lat + ', ' + lng, options);
        }
      });
    }
    console.log(await this.launchNavigator.availableApps());
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Vyberte aplikaci k navigování',
      cssClass: 'apps-action-sheet',
      mode: 'md',
      buttons
    });
    await actionSheet.present();
  }

}

